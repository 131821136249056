import React, { Component, useState } from 'react';

import styled from 'styled-components'

const BarcodeFont = `'Libre Barcode 39 Text', cursive`
const MajorMonoFont = `'Major Mono Display', monospace`

const COLOURS = {
    white: '#ffffff',
    alabaster: '#ECEBE4',
    wildBlueYonder: '#A6B5C9',
    shadowBlue: '#748CAB',
    deepSpaceSparkle: '#3E5C76',
    yankeesBlue: '#1D2D44',
    eerieBlack: '#0D1321',
    metallicSeaweed: '#077187',
    malachite: '#16DB65',
    black: '#000000'
}

// setup the base style for the whole page
const Container = styled.div`
    display: flex;
    flex-direction: column;

    width: 100vw;
    height: 100vh;
    position: fixed;

    // background-color: ${COLOURS.alabaster};    
    background-color: #00000000;

    z-index: 3
`;

const TextWrapper = styled.div`
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    
    position: absolute;
    z-index: 4;

    font-family: ${MajorMonoFont};
    // font-size: 1.4em;
    color: ${COLOURS.malachite};

    background-color: #ffffff00;
`;

const TextContent = styled.div`
    // position: absolute;
    // left: 40px;
    // bottom: 40px;
`;

const Letter = styled.span`
    padding-right: 4px;
    &:last-child {
        padding: 0;
    }
`;

const Button = styled.button`

    background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
    outline: inherit;
    
    font-family: ${BarcodeFont};
    font-size: 3em;
    color: ${COLOURS.malachite};

    padding: 40px 0px;

    text-shadow: 2.6208764473832513px 0 1px rgba(0,30,255,0.5), -2.6208764473832513px 0 1px rgba(255,0,80,0.3), 0 0 3px;
`;

const CRT = styled.div`

    width: 100vw;
    height: 100vh;
    position: fixed;

    z-index: 0;

    background-color: ${COLOURS.yankeesBlue};

    &:before {
        content: " ";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: linear-gradient(rgba(18, 16, 16, 0) 50%, rgba(0, 0, 0, 0.25) 50%), linear-gradient(90deg, rgba(255, 0, 0, 0.06), rgba(0, 255, 0, 0.02), rgba(0, 0, 255, 0.06));
        z-index: 2;
        background-size: 100% 2px, 3px 100%;
        // pointer-events: none;
    }

    &:after {
        content: " ";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: rgba(18, 16, 16, 0.1);
        opacity: 0;
        z-index: 2;
        // pointer-events: none;
    }

    text-shadow: 2.6208764473832513px 0 1px rgba(0,30,255,0.5), -2.6208764473832513px 0 1px rgba(255,0,80,0.3), 0 0 3px;
`;

const chars = "abcdefghijklmnopqrstuvwxyz0123456789@#$%^&*()"
// const chars = "01"
const char = () => chars.charAt(Math.floor(Math.random() * chars.length)).toLowerCase()
const shuffle = x => {
    let i = x.length - 1
    for (; i > 0; --i) {
        let j = Math.floor(Math.random() * (i + 1));
        let temp = x[i];
        x[i] = x[j];
        x[j] = temp;
    }
}
const MAX_LENGTH = 66//40
const MAX_MUTATIONS = 4
const OFFSET = 4
const UPPER_OPACITY = 0.9
const LOWER_OPACITY = 0.08
const LETTER_DELAY = 1

const delay = delay => {
    return new Promise(function (resolve, reject) {
        setTimeout(function () {
            resolve()
        }, delay);
    });
}

class MutatingText extends Component {

    constructor(props) {
        super(props)

        let indices = []
        for (let i = 0; i < MAX_LENGTH; ++i) indices.push(i)

        this.state = {
            value: [],
            target: this.props.target,
            secondary: this.props.secondary,
            indices,
            mutationCount: 0,
            haltMutation: [],
            opacity: [],
            keys: [],
            renderPrimary: true,
            renderSecondary: true

        }

        this.mutate = this.mutate.bind(this)
    }

    componentDidMount() {
        let newTarget = []
        let newSecondary = []
        let { target, secondary } = this.props
        let { value, haltMutation, opacity, keys } = this.state

        // initialise the on-screen value to a random array of chars
        for (let i = 0; i < MAX_LENGTH; ++i) {
            value.push(char())
            haltMutation.push(Math.floor(Math.random() * (MAX_MUTATIONS - 1)) + 1)
            opacity.push(1.0)
            keys.push(char() + char() + char() + char())
        }

        // build a target array that we will use to correctly place
        // the target characters

        // add the first offset placeholders
        for (let i = 0; i < OFFSET; ++i) newTarget.push('')
        for (let i = 0; i < OFFSET; ++i) newSecondary.push('')

        // append the target characters
        newTarget = newTarget.concat(target.split(''))
        newSecondary = newSecondary.concat(secondary.split(''))

        // append the remaining placeholders
        let M = MAX_LENGTH - newTarget.length
        for (let i = 0; i < M; ++i) newTarget.push('')

        let M2 = MAX_LENGTH - newSecondary.length
        for (let i = 0; i < M2; ++i) newSecondary.push('')

        this.setState({
            value,
            target: newTarget,
            secondary: newSecondary,
            haltMutation,
            keys
        }, this.mutate)
    }

    shouldRenderPrimary() {
        this.setState({ renderPrimary: true, renderSecondary: false, mutationCount: 0 }, this.mutate)
    }

    shouldRenderSecondary() {
        this.setState({ renderPrimary: false, renderSecondary: true, mutationCount: 0 }, this.mutate)
    }

    async mutate() {
        let { value, target, indices, mutationCount, haltMutation, opacity, secondary, renderPrimary } = this.state

        const __target = renderPrimary ? target : secondary

        if (mutationCount >= MAX_MUTATIONS) {
            return
        };

        shuffle(indices)

        const loop = i => {
            setTimeout(() => {

                const idx = indices[i]

                // if this isn't a valid char
                if (__target[idx] === '') {
                    value[idx] = char()
                    opacity[idx] = Math.random() * opacity[idx] + LOWER_OPACITY
                    this.setState({ value, opacity })
                } else { // this is one of the valid characters

                    // check if we should halt the update for this character
                    if (mutationCount >= haltMutation[idx]) {
                        value[idx] = __target[idx]
                        opacity[idx] = UPPER_OPACITY
                        this.setState({ value, opacity })
                    } else {
                        value[idx] = char()
                        opacity[idx] = Math.random() * opacity[idx] + LOWER_OPACITY
                        this.setState({ value, opacity })
                    }
                }

                if (--i) loop(i)
                else {
                    ++mutationCount
                    this.setState({
                        mutationCount
                    }, this.mutate)
                }
            }, LETTER_DELAY);
        }

        loop(MAX_LENGTH)
    }

    render() {
        const { value, opacity, keys, renderPrimary } = this.state
        const { renderSecondary } = this.props

        const isRenderingSecondary = this.state.renderSecondary

        if (renderPrimary && renderSecondary) {
            this.shouldRenderSecondary()
        }

        if ( isRenderingSecondary && !renderSecondary  ) {
            this.shouldRenderPrimary()
        }
        
        return (
            <p><strong>
                {
                    value.map((item, index) => {
                        return <Letter key={keys[index]} style={{ 'opacity': `${opacity[index]}` }}>{item}</Letter>
                    })
                }
            </strong></p>
        )
    }
}

const Base = () => {

    const [renderSecondary, setRenderSecondary] = useState(false)
    const toggleDetails = () => { setRenderSecondary(!renderSecondary) }

    return (
        <CRT>
            <Container>
                <TextWrapper>
                    <TextContent>
                        <MutatingText key={'1'}
                            target={`cyberspace. A consensual hallucination experienced`}
                            secondary={``}
                            renderSecondary={renderSecondary}
                        />
                        <MutatingText key={'2'}
                            target={`daily by billions of legitimate operators, in every`}
                            secondary={``}
                            renderSecondary={renderSecondary}
                        />
                        <MutatingText key={'3'}
                            target={`nation, by children being taught mathematical`}
                            secondary={``}
                            renderSecondary={renderSecondary}
                        />
                        <MutatingText key={'4'}
                            target={`concepts... a graphic representation of data abstracted`}
                            secondary={`jordan campbell`}
                            renderSecondary={renderSecondary}
                        />
                        <MutatingText key={'5'}
                            target={`from banks of every computer in the human system. `}
                            secondary={`software engineer`}
                            renderSecondary={renderSecondary}
                        />
                        <MutatingText key={'6'}
                            target={`unthinkable complexity. lines of light ranged in the`}
                            secondary={`jordan@astro.codes`}
                            renderSecondary={renderSecondary}
                        />
                        <MutatingText key={'7'}
                            target={`nonspace of the mind, clusters and constellations of data.`}
                            secondary={`〉/dev-log`}
                            renderSecondary={renderSecondary}
                        />
                        <MutatingText key={'8'}
                            target={`like city lights, receding...`}
                            secondary={``}
                            renderSecondary={renderSecondary}
                        />
                        <MutatingText key={'9'}
                            target={``}
                            secondary={``}
                            renderSecondary={renderSecondary}
                        />
                        <MutatingText key={'10'}
                            target={`〉 William Gibson, Neuromancer.`}
                            secondary={``}
                            renderSecondary={renderSecondary}
                        />
                    </TextContent>

                    <Button onClick={toggleDetails}>who am i</Button>

                </TextWrapper>
            </Container>
        </CRT>
    )
}

export default Base;