import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import { BrowserRouter as Router, Route } from 'react-router-dom';
import Base from './components/Base';

ReactDOM.render(
    <Router>
        <Route exact path={'/'} component={Base} />
    </Router>, 
    document.getElementById('root'));


    {/* <Route path={'/dev-log'} component={DevLog} /> */}